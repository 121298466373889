import { styled } from '@mui/material/styles';
import { Popper, ListSubheader } from '@mui/material';
import { tannerGray } from '../ThemeProvider/colors';
import TextField from '../TextField';
import Grid from '../Grid';

export const AutocompleteWrapper = styled(Grid)(() => ({
  maxWidth: '100%',
}));

export const ListBoxWrapper = styled(Grid)(() => ({
  maxHeight: '600px',
  overflowY: 'auto',
  paddingTop: '8px',
  boxSizing: 'border-box',
}));

export const ActionContainer = styled(Grid)(() => ({
  height: 48,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'sticky',
  padding: '0 4px',
}));

export const DividerContainer = styled(Grid)(() => ({
  borderTop: `1px solid ${tannerGray[300]}`,
}));

export const RecordsCountContainer = styled('div')(() => ({
  height: 42,
  display: 'flex',
  alignItems: 'center',
  margin: 0,
  padding: 0,
}));

export const StyledPopper = styled(Popper)({
  '& .MuiAutocomplete-paper': {
    overflow: 'hidden',
  },
  '& .MuiAutocomplete-listbox .MuiListSubheader-root': {
    paddingRight: 8,
    paddingLeft: 8,
    maxHeight: 48,
  },
  '& .MuiAutocomplete-listbox .MuiMenuItem-root': {
    padding: 0,
    paddingLeft: 7,
    border: 'none',
    backgroundColor: 'light',
    height: '32px',
  },
  '& .MuiAutocomplete-listbox .MuiMenuItem-root .MuiButtonBase-root': {
    marginRight: '0 !important',
  },
  '& .MuiPaper-root .MuiAutocomplete-listbox': {
    padding: 0,
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
  "& .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected='true']": {
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    '& .Mui-focused': {
      backgroundColor: '#fff',
    },
  },
  '& .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"].Mui-focused':
    {
      backgroundColor: '#fff',
    },
  ".MuiAutocomplete-option[aria-selected='true'].Mui-focused": {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  '& .MuiPaper-root .MuiAutocomplete-listbox > li': {
    borderBottom: 'none',
    '&:last-child': {
      paddingTop: 0,
      marginBottom: '8px',
    },
  },
  '& .MuiPaper-root .recordsCountListSubheader': {
    maxHeight: 42,
  },
});

export const RenderInputWrapper = styled(Grid)({
  maxHeight: 126,
  overflow: 'auto',
  marginTop: 8,
  marginBottom: 2,
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  '& .MuiButtonBase-root': {
    maxWidth: '100%',
  },
  '& .MuiChip-root.MuiChip-root': {
    marginTop: '2px',
  },
});

export const RecordsCountListSubheader = styled(ListSubheader)(({ theme }) => ({
  '.MuiAutocomplete-popper &': {
    minHeight: '42px',
  },
}));

export const StyledTextField = styled(TextField)({
  '& .MuiInputBase-root .MuiInputBase-input': {
    padding: '7px 0 4px 8px',
    marginTop: '2px',
    minWidth: '50px',
  },
});
