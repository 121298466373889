import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { InformationCircle } from '@octanner/prism-icons';
import * as React from 'react';
import Box from '../Box';
import { tannerGray } from '../ThemeProvider/colors';
import Tooltip from '../Tooltip';
import Typography from '../Typography';
import {
  EventContainer,
  StyledTag,
  StyledTimeline,
  TagContainer,
  TimelineContentContainer,
} from './styles';
import { Props } from './types';

const Timeline = ({
  events,
  variant = 'simple',
  position = 'right',
}: Props) => {
  const isComplex = variant === 'complex';
  return (
    <StyledTimeline position={position} data-testid="timeline">
      {events.map(
        (
          {
            title,
            timeFrame,
            timeFrameUnit,
            dotVariant,
            timelineItemFooter,
            button,
            tags,
            description,
          },
          i,
        ) => (
          <EventContainer data-testid="event-container" key={title}>
            <TimelineItem>
              {isComplex && (timeFrame || timeFrameUnit) && (
                <TimelineOppositeContent data-testid="timeline-opposite-content">
                  <Typography>
                    {timeFrame} {timeFrameUnit}
                  </Typography>
                </TimelineOppositeContent>
              )}
              <TimelineSeparator>
                <TimelineDot variant={dotVariant ?? 'filled'} />
                {isComplex ? (
                  <TimelineConnector />
                ) : (
                  i !== events.length - 1 && <TimelineConnector />
                )}
              </TimelineSeparator>
              <TimelineContent data-testid="timeline-content">
                <TimelineContentContainer
                  hasFooter={!!timelineItemFooter}
                  variant={variant}
                >
                  {isComplex && position === 'left' && button && (
                    <Box data-testid="left-button">{button()}</Box>
                  )}
                  <Box>
                    <Typography
                      variant="body1"
                      fontWeight={isComplex ? 800 : 500}
                    >
                      {title}
                    </Typography>
                    {isComplex && tags && (
                      <TagContainer
                        data-testid="tag-container"
                        position={position}
                      >
                        {tags.map((tag, i) => (
                          <StyledTag
                            key={title + tag.title}
                            variant="archived"
                            data-testid={`tag:${tag.title}`}
                          >
                            {tag.title}
                            {tag.infoText && (
                              <Tooltip title={tag.infoText} placement="top">
                                <InformationCircle
                                  sx={{ color: tannerGray[400] }}
                                />
                              </Tooltip>
                            )}
                          </StyledTag>
                        ))}
                      </TagContainer>
                    )}
                    {isComplex && description && (
                      <Typography data-testid="timeline-item-description-text">
                        {description}
                      </Typography>
                    )}
                  </Box>
                  {isComplex && position === 'right' && button && (
                    <Box data-testid="right-button">{button()}</Box>
                  )}
                </TimelineContentContainer>
                {isComplex && timelineItemFooter && (
                  <Box data-testid="timeline-item-footer-content">
                    {timelineItemFooter()}
                  </Box>
                )}
              </TimelineContent>
            </TimelineItem>
          </EventContainer>
        ),
      )}
    </StyledTimeline>
  );
};

export default Timeline;
