import { Timeline, timelineOppositeContentClasses } from '@mui/lab';
import Box from '../Box';
import styled from '@emotion/styled';
import Tag from '../Tag';
import { ITag } from '../Tag/types';
import { tannerGray } from '../ThemeProvider/colors';
import { Position, TimelineContentContainerProps } from './types';

export const EventContainer = styled(Box)({
  justifySelf: 'space-between',
});

export const TimelineContentContainer = styled(
  Box,
)<TimelineContentContainerProps>(({ hasFooter, variant }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'space-between',
  paddingBottom: hasFooter ? '8px' : '16px',
  borderBottom: hasFooter
    ? 'none'
    : variant === 'complex'
    ? `1px solid ${tannerGray[200]}`
    : 'none',
}));

export const TagContainer = styled('div')<{ position: Position }>(
  ({ position }) => ({
    display: 'flex',
    gap: '4px',
    flexWrap: 'wrap',
    margin: '8px 0',
    maxWidth: '775px',
    justifyContent: position === 'right' ? 'flex-start' : 'flex-end',
  }),
);

export const StyledTag = styled(Tag)<ITag>({
  width: 'fit-content',
  maxWidth: '250px',
});

export const StyledTimeline = styled(Timeline)({
  [`& .${timelineOppositeContentClasses.root}`]: { flex: 0.2 },
});
