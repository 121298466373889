import React from 'react';
import { ArrowTailLeft, Close } from '@octanner/prism-icons';
import DialogContent from '../DialogContent';
import { useTranslation } from 'react-i18next';
import {
  StyledArrowLeft,
  StyledNoBackTextDiv,
  StyledBackButton,
  StyledDefaultBackText,
  StyledIconButton,
  StyledButton,
  Wrapper,
  StyledTypography,
  StyledNoBackTextDivMobile,
} from './styles';
import { ModalNavProps } from './types';

const ModalNav: React.FC<
  ModalNavProps & React.ComponentProps<typeof DialogContent>
> = ({
  backText,
  id,
  onBack,
  onClose,
  title,
  titleTypographyProps,
  disableCloseButton,
  disableBackText,
  backButtonProps,
  upperShadow,
  backDataTestId,
  backTextAutoFocus,
  isMobile,
}) => {
  const { t } = useTranslation();

  const BackButton = () => {
    const defaultBackText: string = backText || t('prism-header:back', 'Back');
    if (onBack === undefined || onBack === null) {
      return <StyledNoBackTextDiv />;
    }
    return (
      <>
        {!isMobile ? (
          <StyledBackButton
            autoFocus={backTextAutoFocus}
            aria-label="back"
            data-testid={backDataTestId ?? 'Dialog:onBackBtn'}
            variant="text"
            role="button"
            onClick={onBack}
            {...backButtonProps}
            disabled={disableBackText}
            startIcon={<StyledArrowLeft />}
          >
            <StyledDefaultBackText>{defaultBackText}</StyledDefaultBackText>
          </StyledBackButton>
        ) : (
          <StyledIconButton
            onClick={onBack}
            disabled={disableBackText}
            size="large"
          >
            <ArrowTailLeft />
          </StyledIconButton>
        )}
      </>
    );
  };

  const CloseButton = () => {
    return !isMobile ? (
      <StyledButton
        aria-label="close"
        data-testid="Dialog:onCloseBtn"
        variant="text"
        onClick={onClose}
        disabled={disableCloseButton}
        endIcon={<Close />}
      >
        {t('prism-header:close', 'Close')}
      </StyledButton>
    ) : (
      <StyledIconButton
        aria-label="close"
        data-testid="Dialog:onCloseBtn"
        onClick={onClose}
        size="large"
        disabled={disableCloseButton}
      >
        <Close />
      </StyledIconButton>
    );
  };

  const NoBackButton = isMobile
    ? StyledNoBackTextDivMobile
    : StyledNoBackTextDiv;

  return (
    <Wrapper isMobile={isMobile} upperShadow={upperShadow}>
      {onBack ? <BackButton /> : <NoBackButton />}
      <StyledTypography
        component="h2"
        align="center"
        variant="h2"
        id={`${id}-title`}
        {...titleTypographyProps}
      >
        {title}
      </StyledTypography>
      {onClose && <CloseButton />}
    </Wrapper>
  );
};

export default ModalNav;
