import { SxProps, Theme, useTheme } from '@mui/material';
import MuiButton from '@mui/material/Button';
import React, { forwardRef } from 'react';
import DotsLoader from '../Loader/dotsLoader';
import { tannerBlue } from '../ThemeProvider/colors';
import {
  ButtonContent,
  FabButton,
  FabContent,
  LoaderContainer,
  buttonTypeCss,
  extraLargeCss,
  smallCss,
} from './styles';
import { ButtonProps, FabButtonProps } from './types';

const Loader: React.FC = () => {
  return (
    <LoaderContainer>
      <DotsLoader type="button" />
    </LoaderContainer>
  );
};

const evaluateSx = (
  size: string | undefined,
  buttonType: string | undefined,
  sx: SxProps<Theme> | undefined,
) => {
  let customStyles = { ...sx };
  if (size === 'extraLarge') {
    customStyles = { ...extraLargeCss, ...customStyles };
  } else if (size === 'small') {
    customStyles = { ...smallCss, ...customStyles };
  }
  if (buttonType) {
    customStyles = { ...buttonTypeCss[buttonType], ...customStyles };
  }
  return customStyles;
};

const Button = forwardRef<HTMLButtonElement, ButtonProps | FabButtonProps>(
  (
    {
      loading,
      children,
      color = 'primary',
      adminColor,
      size,
      sx,
      buttonType,
      fab,
      ...props
    },
    ref,
  ) => {
    const commonProps = {
      disabled: loading,
      color: adminColor && color === 'primary' ? 'blue' : color,
      ref,
      sx: evaluateSx(size, buttonType, sx),
      ...props,
    };

    const theme = useTheme();
    const themeColor =
      adminColor && color === 'primary'
        ? tannerBlue['500']
        : theme.palette.primary.main;

    return fab ? (
      <FabButton {...(commonProps as FabButtonProps)} themeColor={themeColor}>
        <FabContent loading={loading}>{children}</FabContent>
        {loading && <Loader />}
      </FabButton>
    ) : (
      <MuiButton {...(commonProps as ButtonProps)}>
        <ButtonContent loading={loading}>{children}</ButtonContent>
        {loading && <Loader />}
      </MuiButton>
    );
  },
);

export default Button;
